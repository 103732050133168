(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .controller("EAccountStatistics", controller);
    controller.$inject = [
        "$filter",
        "$state",
        "$http",
        "$scope",
        "$stateParams",
    ];
    function controller($filter, $state, $http, $scope, $stateParams) {
        var vm = this;
        console.log("$stateParams=", $stateParams);
        vm.loading = false;
        vm.type = $stateParams.type;
        vm.itemsPerPage = 20;
        vm.page = $stateParams.page;
        vm.statisticsList = [];
        vm.startTime = new Date(
            new Date().getTime() - 1000 * 60 * 60 * 24 * 30
        );
        vm.endTime = new Date();
        console.log("vm.endTime=", vm.endTime);
        console.log("vm.startTime=", vm.startTime);
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;
        vm.openCalendar = function (date) {
            vm.datePickerOpenStatus[date] = true;
        };
        vm.switchType = function (params) {
            if (vm.type !== params) {
                vm.type = params;
                vm.transition(true);
            }
        };
        vm.dateChange = function (event) {
            if (
                new Date(vm.startTime).getTime() >
                new Date(vm.endTime).getTime()
            ) {
                alert("结束时间必须在开始时间之后，请重新录入结束时间！");
                return false;
            }
            vm.transition(true);
        };
        vm.transition = function (params) {
            if (params) {
                vm.page = 1;
            }
            vm.loading = true;
            $http({
                url: "api/report/eaccounteconomylist",
                method: "GET",
                params: {
                    startTime: $filter("date")(vm.startTime, "yyyy-MM-dd"),
                    endTime: $filter("date")(vm.endTime, "yyyy-MM-dd"),
                    type: vm.type,
                    page: vm.page,
                    size: vm.itemsPerPage,
                },
            }).then(function (res) {
                vm.totalItems = res.data.total;
                vm.sumCoupons = res.data.sumCoupons;
                vm.statisticsList = res.data.data;
                console.log("vm.statisticsList=", vm.statisticsList);
                vm.loading = false;
            });
        };
        vm.detialTime = null;
        vm.loading2 = false;
        vm.page2 = 1;
        vm.totalItems2 = 0;
        vm.detailList = [];
        vm.sort = 2;
        vm.order = 0;
        vm.predicate = "id";
        vm.reverse = false;
        vm.openDetail = function (time) {
            vm.detialTime = time;
            vm.transition2();
            vm.page2 = 1;
            vm.totalItems2 = 0;
            vm.detailList = [];
            $("#myModal_add_member").modal("show");
        };
        vm.transition2 = function (params) {
            if (params) {
                console.log("vm.predicate=", vm.predicate);
                console.log("vm.reverse=", vm.reverse);
                if (vm.predicate === "time") {
                    vm.sort = 1;
                } else if (vm.predicate === "id") {
                    vm.sort = 2;
                } else if (vm.predicate === "coupon") {
                    vm.sort = 4;
                }
                vm.order = vm.reverse ? 1 : 0;
            }
            var detailParams = {
                time: vm.detialTime,
                type: vm.type,
                page: vm.page2,
                size: 10,
                sort: vm.sort,
                order: vm.order,
            };
            if (vm.type == 2 || vm.type == 3) {
                detailParams.startTime = $filter("date")(
                    vm.startTime,
                    "yyyy-MM-dd"
                );
                detailParams.endTime = $filter("date")(
                    vm.endTime,
                    "yyyy-MM-dd"
                );
            }
            vm.loading2 = true;
            $http({
                url: "api/report/eaccounteconomydetail",
                method: "GET",
                params: detailParams,
            }).then(function (res) {
                vm.totalItems2 = res.data.total;
                vm.detailList = res.data.data;
                console.log("vm.detailList=", vm.detailList);
                vm.loading2 = false;
            });
        };
        vm.transition();
        vm.exportData = function () {
            var downUrl =
                "/api/report/exportEaccounteconomylist?startTime=" +
                $filter("date")(vm.startTime, "yyyy-MM-dd") +
                "&endTime=" +
                $filter("date")(vm.endTime, "yyyy-MM-dd") +
                "&type=" +
                vm.type;
            var a = document.createElement("a");
            a.href = downUrl;
            a.target = "_blank";
            document.body.appendChild(a); //将标签DOM，放置页面
            a.click();
            window.URL.revokeObjectURL(downUrl); //释放 url 对象内存
            document.body.removeChild(a);
        };
        vm.exportData2 = function () {
            var downUrl =
                "/api/report/exportEaccounteconomydetail?startTime=" +
                $filter("date")(vm.startTime, "yyyy-MM-dd") +
                "&endTime=" +
                $filter("date")(vm.endTime, "yyyy-MM-dd") +
                "&type=" +
                vm.type +
                "&time=" +
                vm.detialTime;
            var a = document.createElement("a");
            a.href = downUrl;
            a.target = "_blank";
            document.body.appendChild(a); //将标签DOM，放置页面
            a.click();
            window.URL.revokeObjectURL(downUrl); //释放 url 对象内存
            document.body.removeChild(a);
        };
    }
})();
